<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_resources_emt">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='desktop'>CV</th>
                                        <th class='all' style="text-align: center;">Matr.</th>
                                        <th class='all'>Cognome</th>
                                        <th class='desktop'>Nome</th>
                                        <th class='desktop'>Ut. Assoc.</th>
                                        <th class='all'>E-Mail</th>
                                        <th class='all'>Cellulare</th>
                                        <th class='desktop'>T. Contr.</th>
                                        <th class='desktop'>Azienda</th>
                                        <th class='desktop'>I. Rapp.</th>
                                        <th class='desktop'>F. Rapp.</th>
                                        <th class='desktop'>Cost. Gior. (€)</th>

                                        <th class='desktop' style="text-align: center;">Sgravi</th>

                                        <th class='desktop'>Diaria (€)</th>
                                        <th class='desktop'>Ticket</th>
                                        <th class='desktop'>P. Orario</th>
                                        <th class='desktop'>EBadge</th>
                                        <th class='desktop' style="text-align:center;">Corsi</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="20">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/icon_delete_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina filtro" @click="btnResetSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupotpdelete" style="display:none">

            <template>
                <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Codice OTP inviatoLe via mail

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelOtpDelete" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmOtpDelete" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




        <div class="contpopuptiporisorsa" style="display:none">

            <template>
                <modal name="popupOtpTipoRisorsa" :clickToClose="false" :width="262" :height="234">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Aggiungi Risorsa

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-radio-group v-model="valueTypeResource">
                                <v-radio label="Dipendente" value="DIP"></v-radio>
                                <v-radio label="Parita IVA" value="PIVA"></v-radio>
                            </v-radio-group>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-add-resource" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelTypeResource" />

                                <v-img alt="" class="btn-confirm-add-resource" contain
                                    src="@/assets/btn_confirm3_emt.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmTypeResource" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </div>
</template>

<script>
import apirisorse from "../utils/risorse/apirisorse";
//import apiusers from "../utils/users/apiusers";
import apiotp from "../utils/otp/apiotp";


import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        window.curCompany = window.$cookies.get("sel_filter_risorse");

        window.curSrcText = "";

        window.myVal = "";

        window.curTipoContr = "-1";

        // window.costiAziendali = null;

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");



        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();



            });





        });

        setTimeout(() => {

            this.syncAziende();

            //this.asyncCostiAziendali();

        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            var ids = "";

            switch (data) {

                case "btn_delete":


                    this.getOtpDelete();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":



                    $(".contpopuptiporisorsa").show();

                    this.$modal.show('popupOtpTipoRisorsa');


                    break;

                case "btn_edit":



                    ids = "";
                    var tipo_contr = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");

                            tipo_contr = $(this).attr("tipo_contr_imp");

                        }

                    });

                    console.log("IDS SEL:", ids);

                    window.$cookies.set("type_res_ins", tipo_contr, "9y");


                    router.push({
                        path: "/editRisorsa/" + ids
                    });



                    break;

                case "btn_back":
                    router.push({
                        path: "/filterRisorse"
                    });
                    break;


                case "btn_clienti":


                    var v_row = $("table").find(".active-row");

                    var item = window.table.row(v_row).data();

                    console.log("ITEM ROW: ", item);



                    window.$cookies.set("sel_resource_name_clienti_assoc", item.fld_name, "9y");


                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    window.$cookies.set("sel_resource_clienti_assoc", ids, "9y");

                    router.push({
                        path: "/risorseClientiProgetti"
                    });

                    break;


                case "btn_corsi":

                    var id_risorsa = "";
                    var ele_sel = null;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            ele_sel = $(this);

                            if (id_risorsa != "") {
                                id_risorsa = id_risorsa + ",";
                            }
                            id_risorsa = id_risorsa + $(this).attr("data-id");
                        }

                    });

                    window.$cookies.set("sel_id_resource_corsi", id_risorsa, "9y");

                    var v_row2 = ele_sel.parent().parent();

                    console.log("ROW SEL: ", v_row2);

                    var item2 = window.table.row(v_row2).data();

                    console.log("ITEM ROW: ", item2);

                    window.$cookies.set("sel_name_resource_corsi", item2.fld_name, "9y");


                    router.push({
                        path: "/corsiRisorse"
                    });


                    break;


                default:
                    break;

            }
        });


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);


    },
    data: () => ({

        valueTypeResource: "DIP",

        valueOtpDelete: "",


        aziende: [],

        textSearch: "",

        sheetAziende: false,
        search: '',


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    methods: {


        btnCancelTypeResource: function () {

            this.$modal.hide('popupOtpTipoRisorsa');

        },

        btnConfirmTypeResource: function () {

            window.$cookies.set("type_res_ins", this.valueTypeResource, "9y");

            router.push({
                path: "/editRisorsa/0"
            });

        },






        btnResetSearch: function () {


            window.curSrcText = "";




            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },



        getOtpDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            window.myVal = "";

            that.valueOtpDelete = "";

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMail(
                v_token,
                'emt',
                'elimina_risorsa',
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMail", res);

                window.myVal = res.data.Result;


                setTimeout(() => {

                    $(".contpopupotpdelete").show();

                    this.$modal.show('popupOtpDelete');

                    setTimeout(() => {

                        $("#fldValueOtpDelete").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelOtpDelete: function () {

            this.$modal.hide('popupOtpDelete');


        },

        btnConfirmOtpDelete: function () {


            if (this.valueOtpDelete != "") {

                console.log("OTP INP: ", this.valueOtpDelete);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

                if (window.myVal == btoa(this.valueOtpDelete)) {

                    this.$modal.hide('popupOtpDelete');

                    this.deleteRow();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },



        syncAziende: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                that.$root.$children[0].showProgress = false;

                that.aziende = JSON.parse(window.$cookies.get("companyUser"));

            }, 300);


        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            window.$cookies.set("sel_filter_risorse", azienda.companyid, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                    link: "/settings",
                    id: "btn_add",
                    disabled: false,
                    title: "Aggiungi",
                    width: 30
                });

                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30
                    });


                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/referenti_emt.png",
                        link: "/info",
                        id: "btn_clienti",
                        disabled: false,
                        title: "Clienti",
                        width: 30
                    });



                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30
                    });

                }



                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/corsi_emt.png"),
                        link: "/info",
                        id: "btn_corsi",
                        disabled: false,
                        title: "Corsi",
                        width: 35
                    });


                }




                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },



        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");



                window.curStatus = "S"; // in forza

                window.columns = [
                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: true,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'CV',
                        orderable: true,
                        name: 'CV',
                        width: '4%'
                    },



                    {
                        data: 'matricola_presente',
                        orderable: false,
                        name: 'matricola_presente',
                        width: '1%'
                    },



                    {
                        data: 'surname',
                        orderable: true,
                        name: 'surname',
                        width: '6%'
                    },

                    {
                        data: 'name',
                        orderable: true,
                        name: 'name',
                        width: '6%'
                    },

                    {
                        data: 'login',
                        orderable: true,
                        name: 'login',
                        width: '4%'
                    },

                    {
                        data: 'mail',
                        orderable: true,
                        name: 'mail',
                        width: '4%'
                    },

                    {
                        data: 'tel_cell',
                        orderable: true,
                        name: 'tel_cell',
                        width: '4%'
                    },

                    {
                        data: 'desc_tipo_contratto',
                        orderable: true,
                        name: 'desc_tipo_contratto',
                        width: '4%'
                    },

                    {
                        data: 'desc_company',
                        orderable: true,
                        name: 'desc_company',
                        width: '8%'
                    },

                    {
                        data: 'inizio_rapporto',
                        orderable: true,
                        name: 'inizio_rapporto',
                        width: '6%'
                    },

                    {
                        data: 'fine_rapporto',
                        orderable: true,
                        name: 'fine_rapporto',
                        width: '6%'
                    },




                    {
                        data: 'costo_giornaliero',
                        orderable: true,
                        name: 'costo_giornaliero',
                        width: '8%'
                    },

                    {
                        data: 'sgravi',
                        orderable: false,
                        name: 'sgravi',
                        width: '1%'
                    },




                    {
                        data: 'diaria',
                        orderable: true,
                        name: 'diaria',
                        width: '5%'
                    },

                    {
                        data: 'ticket',
                        orderable: true,
                        name: 'ticket',
                        width: '4%'
                    },

                    {
                        data: 'piano_orario',
                        orderable: true,
                        name: 'piano_orario',
                        width: '4%'
                    },

                    {
                        data: 'ebadge',
                        orderable: true,
                        name: 'ebadge',
                        width: '4%'
                    },

                    {
                        data: 'corsi',
                        orderable: false,
                        name: 'corsi',
                        width: '1%'
                    },





                ];


                $(".spin").show();




                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("goGetResources") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.curSrcText = window.curSrcText;

                            valori.curTipoContr = window.curTipoContr;

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);



                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("goGetResources") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    // $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                var sel = `
                <div title='Stato'><ion-select data-id="0" value="S" cancel-text="Chiudi" class="selectstatusall" interface="action-sheet" placeholder=" "><ion-select-option value='-1'>Tutti</ion-select-option><ion-select-option value='S'>In Forza</ion-select-option><ion-select-option value='N'>Non In Forza</ion-select-option></ion-select></div><img src='/emt/img/aziende-emt.png' style='border:0!important;' class='selazienda' title='Aziende' /><div title='Tipo Contratto'><ion-select data-id="0" value="S" cancel-text="Chiudi" class="selecttipocontr" interface="action-sheet" placeholder=" "><ion-select-option value='-1'>Tutti</ion-select-option><ion-select-option value='APS'>Apprendistato</ion-select-option><ion-select-option value='CINT'>Cassa Integrazione</ion-select-option><ion-select-option value='DET'>Determinato (IT)</ion-select-option>
<ion-select-option value='DIS'>Disoccupato</ion-select-option>
<ion-select-option value='FOR'>Fornitore</ion-select-option>
<ion-select-option value='IND'>Indeterminato (IT)</ion-select-option>
<ion-select-option value='MOB'>Mobilità</ion-select-option>
<ion-select-option value='PIVA'>Partita IVA</ion-select-option>
<ion-select-option value='RIT'>Ritenuta</ion-select-option>
<ion-select-option value='STG'>Stage</ion-select-option>
<ion-select-option value='TCN'>Tirocinio</ion-select-option></ion-select></div><div title='Utenti' class='selgousers'></div>
                  `;

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Risorse</div>
                    ` + sel + `

                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);


                $(".selgousers").on("click", function (opt) {
                    console.log(opt);

                    var id_azi = window.$cookies.get("sel_filter_risorse");

                    window.$cookies.set("sel_filter_azienda_utenti", id_azi, "9y");

                    var a = window.$cookies.get("a");

                    if (a == "N") {

                        router.push({
                            path: "/users"
                        });

                    }


                    if ((a == "S") || (a == "Y")) {

                        router.push({
                            path: "/usersManageBySU"
                        });

                    }

                });





                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });



                $(".selecttipocontr").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();

                    window.curTipoContr = this.value;

                    window.table.ajax.reload();

                });





                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });

                $('body').on('click', '.cont_resources_emt th', function () {


                    console.log("TH-ACTIVE: ", $(this));


                    $(this).removeClass("sorting");



                });


                $('body').on('click', '.cont_resources_emt tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });



                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     width: 35
                        // },




                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30
                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     width: 35
                        // },





                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },



        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        asyncCostiAziendali: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                that.$root.$children[0].showProgress = false;

                window.costiAziendali = JSON.parse(window.$cookies.get("costiAziendali"));

            }, 300);


        },

        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apirisorse.deleteRisorsaMulti(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteRisorsa", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
#fldValueOtpDelete {
    text-align: center;
    font-size: 20px
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_resources_emt #myTable {
    margin-right: 3px;
}

.cont_resources_emt table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_resources_emt table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important;
    vertical-align: middle;
}

.cont_resources_emt th {
    padding-left: 5px !important;
}

.cont_resources_emt ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_resources_emt .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}


.cont_resources_emt #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;

}

.cont_resources_emt div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_resources_emt #myTable_wrapper {
    padding-top: 0px;
}

.cont_resources_emt .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_resources_emt .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 6px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


.cont_resources_emt .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_resources_emt .selectstatusall {
    max-width: 22px;
    min-height: 22px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 44px !important;
    background-image: url(/emt/img/filtro-stato-emt.png);
    text-indent: -119px;
    background-size: 27px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -8px !important;
}


.cont_resources_emt .selecttipocontr {
    max-width: 23px;
    min-height: 23px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 44px !important;
    background-image: url(/emt/img/tipo_contratto_emt.png);
    text-indent: -119px;
    background-size: 27px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -8px !important;
}


.cont_resources_emt .selgousers {
    max-width: 23px;
    min-height: 23px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 44px !important;
    background-image: url(/emt/img/users_menu3.png);
    text-indent: -119px;
    background-size: 27px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -8px !important;
    cursor: pointer;
}





.cont_resources_emt .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.cl_tipo_contratto {
    width: 46px !important;
}

.cont_resources_emt .cl_desc_company {
    width: 140px !important;
}

.cl_inizio_rapporto {
    width: 70px !important;
}

.cl_fine_rapporto {
    width: 70px !important;
}

.cl_totale_ferie {
    width: 60px !important;
}

.cl_costo_giornaliero {
    width: 75px !important;
}

.cl_diaria {
    width: 52px !important;
}

.cl_piano_orario {
    width: 48px !important;
}

.cl_customer_date_start {
    width: 55px !important;
}

.cl_customer_date_end {
    width: 55px !important;
}

.cont_resources_emt .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_resources_emt .checkall {
    width: 13px;
    height: 13px;
}

.cont_resources_emt .val_status {
    width: 10px !important;
}

.cont_resources_emt .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}

.contpopuptiporisorsa .v-label {
    font-size: 10px !important;
}


@media screen and (max-width: 960px) {

    .cont_resources_emt .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_resources_emt #myTable {
        /* margin-top:54px; */
    }

    .cont_resources_emt #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_resources_emt #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_resources_emt #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_resources_emt .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_resources_emt #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_resources_emt .cl_num {
        margin-left: -1px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cl_tipo_contratto {
        display: inline-flex;
    }

    .cl_desc_company {
        display: inline-flex;
    }

    .cl_inizio_rapporto {
        display: inline-flex;
    }

    .cl_fine_rapporto {
        display: inline-flex;
    }

    .cont_resources_emt .cl_checkall {
        padding-left: 20px;
        padding-top: 2px;
    }



}
</style>
